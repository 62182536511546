export const ActiveSubscriptionTypes = [
    'active',
    'trialing'
];

export type BaseResponse<T> = {
    data: T;
}

export type UploadDto = {
    audioUrl: string;
    recordUid: string;
}

export type UserTracks = {
    recordingUrl: string;
    trackUid: string;
    date: string;
    fileId: string;
    model: string;
    timestamps: any[]; // todo - strictly type
    coverPage: string;
}

export type DatabaseUser = {
    uid: string;
    data: DatabaseUserData;
}

export type DatabaseUserData = {
    email: string;
    name: string;
    stripeSessionId: string;
    photo: string | null;
}

export type StripeSessionStatus = {
    price: string;
    status: string;
    customer_email: string;
}

export type FileStatusInfo = {
    fileId: string;
    status: string;
    fileName: string;
    location: string;
};

export type AvailableCurrencies = 'EUR' | 'USD';
